@import '../../../utils/_variables'

.WarehouseSettings
  display: flex
  flex-wrap: wrap
  flex-direction: column

  & > div
    &.spots
      border-bottom: 1px solid $gray-light
      padding-bottom: 30px
      // width: calc(60% - 61px)
      width: 100%

      @media (max-width: 1399px)
        padding-right: 0
        width: 100%
        border-bottom: 2px solid $gray-light
        border-right: none
        padding-bottom: 40px
        margin-bottom: 40px

    .action-icon
      position: relative
      bottom: 2px
      cursor: pointer

      &.save
        opacity: .5
        cursor: not-allowed

        &.updated-true
          opacity: 1
          cursor: pointer

    &.plan
      padding-top: 30px
      // width: calc(40% - 61px)
      width: 100%

      @media (max-width: 1399px)
        padding-left: 0
        width: 100%

      .show-pdf
        display: flex
        align-items: center
        justify-content: stretch
        margin-bottom: 20px

        & > div.delete
          margin-left: 20px
          text-decoration: underline
          cursor: pointer

    .my_table
      .my_row
        display: flex
        align-items: flex-start
        justify-content: space-between

        .my_column
          width: calc((90% - 70px) / 6)

          &.add-spot
            width: calc((96% - 30px) / 2.5)

          &.button
            width: 70px
            text-align: right
