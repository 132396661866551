@import '../../../utils/_variables'

.WarehouseExit
  padding-bottom: 0

  .orders-table
    max-height: calc(100vh - 214px)

  .MuiTableHead-root
    position: sticky
    top: 0
    left: 0
    width: 100%
    background: #fff
    z-index: 2

  .MuiTableCell-root
    position: relative

  .MuiTabs-indicator
    height: 100%
    background: $secondary-green

  .MuiTab-root.Mui-selected
    z-index: 2

  .MuiBox-root
    border-bottom: 2px solid $gray-light

  .letter-icon
    margin: 3px 6px 3px 0
