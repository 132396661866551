.WarehouseExitForm
  h2
    margin-top: 0

  h3
    margin-bottom: 10px

  .form-fields
    width: 102%
    position: relative
    left: -1%

    .form-field
      width: 20%
      padding: 1%

      &.address, &.contactPerson
        width: 40%

      &.reference
        width: 100%

  .positions
    .position
      margin-bottom: 20px

      &:last-of-type
        margin-bottom: 0

    .form
      margin-bottom: -20px

      & > div
        margin-bottom: 20px

  .truck-plates-table-row
    display: flex
    align-items: center
    flex-wrap: nowrap
    margin-top: 15px

    &:first-of-type
      margin-top: 0

    & > div
      min-width: 200px
      width: 200px

      &:first-of-type
        width: 150px
        min-width: 150px

  .goods-selector
    min-height: 100px
    border: 1px solid rgba(118, 118, 118, 0.35)
    border-radius: 5px
    overflow-y: scroll

    .inner
      padding: 10px
