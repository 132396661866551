@import '../../../utils/_variables'

.SystemStandardValues
  .MuiTabs-indicator
    height: 100%
    background: $secondary-green

  .MuiTab-root.Mui-selected
    z-index: 2

  .MuiBox-root
    border-bottom: 2px solid $gray-light

  .input
    margin-bottom: 15px
