.ProductionBatches
  table
    td
      white-space: nowrap

.batch-pop
  overflow: scroll
  h3
    margin: 30px 0 10px

  hr
    margin-top: 30px
