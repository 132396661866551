@import '../../../utils/_variables'

.SystemDefinitions
  .definitions-table
    width: 100%

    .row
      display: flex
      align-items: stretch
      position: relative

      &::after
        content: ''
        width: 1px
        height: 100%
        background: $gray-light
        position: absolute
        top: 0
        left: 30%

      &.break
        .left, .right
          padding-bottom: 30px

      .left
        width: 30%
        padding-right: 20px
        display: inline-block
        padding-bottom: 10px

      .right
        width: calc(70% - 1px)
        padding-left: 20px
        display: inline-block
        padding-bottom: 10px
