.WarehouseEntry
  padding-bottom: 0

  .orders-table
    max-height: calc(100vh - 214px)

  .MuiTableHead-root
    position: sticky
    top: 0
    left: 0
    width: 100%
    background: #fff
    z-index: 2

  .MuiTableCell-root
    position: relative

  table
    td, th
      white-space: nowrap
