@import '../../utils/_variables'

.ContentContainer
  background: $white
  flex: 1
  width: auto

  .content
    padding: 50px 50px
    position: relative
    min-height: calc(100vh - $topNavHeight)
