@import '../../../utils/_variables'

.FilterPop
  position: absolute
  top: 100%
  left: 0
  background: $white
  padding: 15px
  box-shadow: $shadow
  z-index: 9
