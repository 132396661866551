@import '../../../utils/_variables'

.ProcessEntry
  .MuiTabs-indicator
    height: 100%
    background: $secondary-green

  .MuiTab-root.Mui-selected
    z-index: 2

  .MuiBox-root
    border-bottom: 2px solid $gray-light

  .trucks-window
    height: calc(100vh - 370px)
    overflow-y: scroll
    position: relative
    padding: 15px
    border: 1px solid $gray

    .table
      margin-top: 30px

  table
    td, th
      white-space: nowrap
