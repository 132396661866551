.NewLabReportModal
  h2
    margin-top: 0

  .tests
    margin-top: 40px

    .test
      input
        font-size: 80%
        padding: 10px 12px

      .label
        text-align: center
        margin-bottom: 5px
        font-size: 80%
