$white: #fff
$black: #222

$gray-light: #F4F4F4
$gray: #ccc
$gray-dark: #888

$primary-green: rgb(0, 94, 51)
$secondary-green: rgba(6, 137, 36, 41%)
$dark-green: #024111
$green-light: transparentize(#04C633, .8)
$green: green

$yellow: darken(yellow, 5%)
$orange: orange
$red: red

$shadow: 0 10px 30px rgba(0,0,0,.2)

$topNavHeight: 55px
$sidebar-width: 310px
