@import './../../../utils/_variables'

.NewOrderForm
  h2
    margin-top: 0

  h3
    margin-bottom: 10px

  .form-fields
    width: 102%
    position: relative
    left: -1%

    .form-field
      width: 20%
      padding: 1%

      &.address, &.contactPerson
        width: 40%

      &.reference
        width: 100%

  .positions
    .position
      margin-bottom: 20px

      &:last-of-type
        margin-bottom: 0

    .form
      margin-bottom: -20px

      & > div
        margin-bottom: 20px

  .truck-plates-table-row
    display: flex
    align-items: center
    flex-wrap: nowrap
    margin-top: 15px

    &:first-of-type
      margin-top: 0

    & > div
      min-width: 200px
      width: 200px

  // following lines only for heading in first column
      // &:first-of-type
      //   width: 130px
      //   min-width: 130px

    // .row
    //   position: fixed
    //   z-index: 2
    //   height: 65px
    //   display: flex
    //   align-items: center
    //   background: $white

    //   span
    //     position: relative
    //     bottom: 12px

    // & > div.first
    //   margin-left: 150px

  .update-price
    font-size: 13px
    position: absolute
    right: 21px
    bottom: 0
    color: $primary-green
    cursor: pointer
