.ProcessesOverview
  .inner
    align-items: center
    justify-content: center
    height: 100%

    .big-btn
      margin: 30px auto
      width: 28%
      min-width: 300px
      padding: 50px 40px
