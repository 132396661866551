@import '../../utils/_variables'

.Dashboard
  background: $gray-light

  h1
    margin-bottom: 0

  p.header
    margin-top: 0

  input:disabled
    font-weight: bold
