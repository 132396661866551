.ToleranceValues
  .tolerance-table
    width: 100%
    overflow-y: scroll
    padding: 10px 20px 25px
    margin-bottom: 50px

    .inner
      width: auto
      display: flex

    .column
      min-width: 110px
      margin-right: 15px

      &.scroll-helper
        min-width: 0px

      .header
        margin-bottom: 15px
        height: 40px
        display: flex
        align-items: flex-end

  .colors-table
    .table-row
      display: flex
      align-items: center
      margin-bottom: 15px

      &:last-of-type
        margin-bottom: 0

      .table-column
        width: 160px

        &:nth-child(1)
          padding-right: 20px

        &:nth-child(2)
          text-align: center
          width: 50px

    .preAdornment
      text-align: center
      line-height: .6

    input
      text-align: center

  .show-pdf
    display: flex
    align-items: center
    justify-content: stretch
    margin-bottom: 20px

    & > div.delete
      margin-left: 20px
      text-decoration: underline
      cursor: pointer
