.Laboratory
  padding-bottom: 0

  .table-container
    max-height: calc(100vh - 202px)

  td
    white-space: nowrap

    .swatch
      height: 1.2rem
      width: 1.2rem

    .action
      margin-right: 10px

      &:last-of-type
        margin-right: 0
