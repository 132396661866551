@import './../../../utils/_variables'

.Inventory
  padding-bottom: 0

  .inventory-table
    max-height: calc(100vh - 274px)

  table
    td
      white-space: nowrap

    tbody
      tr
        cursor: pointer

.update-field
  font-size: 13px
  position: absolute
  right: 21px
  bottom: 0
  color: $primary-green
  cursor: pointer

.detail-pop
  h3
    margin: 40px 0 15px

  .documents
    a
      text-decoration: none

    input
      cursor: pointer
