@import '../../utils/_variables'

.TopNav
  background: $gray-light
  display: flex
  justify-content: space-between
  align-items: center
  padding: 0 30px 0 calc(30px + $sidebar-width)
  height: $topNavHeight
  position: absolute
  width: 100vw
  left: 0
  top: 0
  z-index: 1
  box-shadow: 0 10px 30px rgba(0,0,0,.1)

  & > div
    width: 50%

.App.role-worker .TopNav
  padding: 0 30px!important
