@import '../../../utils/_variables'

.ProductionBatchesForm
  .batch-pop
    overflow: scroll
    h3
      margin: 30px 0 10px

    hr
      margin-top: 30px

.batches-select-storages-pop
  .MuiTabs-indicator
    height: 100%
    background: $secondary-green

  .MuiTab-root.Mui-selected
    z-index: 2

  .MuiBox-root
    border-bottom: 2px solid $gray-light
