@import '../../utils/_variables'

.LoginForm
  max-width: 500px
  background: $white
  padding: 30px 20px
  box-shadow: $shadow
  border-radius: 5px
  text-align: center

  .MuiTextField-root
    margin-bottom: 15px

  .switcher
    margin: 15px auto 0
    cursor: pointer
    text-decoration: underline
    color: $gray
