@import '../../utils/_variables'

.Sidebar
  color: white
  background: $dark-green
  padding: 20px 10px
  font-weight: 600
  width: $sidebar-width
  height: 100%
  position: fixed
  left: 0
  top: 0
  z-index: 3
  display: flex
  flex-direction: column

  .header
    margin-bottom: 40px
    padding: 0 15px
    font-size: 1.3rem

  .nav
    .item
      display: flex
      align-items: center
      color: $white
      text-decoration: none
      padding: 10px 20px
      margin: 0 0 10px

      &.active
        background: transparentize(#fff, .7)
        border-radius: 10px

      .icon
        width: 20px
        height: 20px
        margin-right: 15px

        img
          width: 100%

    .sub-nav
      width: 90%
      margin-left: auto

  .process-btn-wrapper
    margin: auto auto 0px
    width: 90%
    position: relative

    &:hover .dropdown
      z-index: 1
      opacity: 1
      display: block

    .process-btn
      background: $gray-light
      color: $black
      padding: 10px
      text-align: center
      cursor: pointer
      border-radius: 10px
      text-decoration: none
      display: block

    .dropdown
      z-index: -1
      opacity: 0
      background: $white
      position: absolute
      left: 0
      bottom: 100%
      transition: opacity .3s
      width: 100%
      display: none

      .item
        display: block
        padding: 10px
        width: 100%
        text-align: center
        border-bottom: 1px solid $gray
        text-decoration: none
        color: $black
