.ProcessProduction
  .box
    margin: 30px auto 50px
    padding: 20px 20px 30px
    position: relative

    .overlay
      position: absolute
      top: 0
      right: 0
      left: 0
      bottom: 0
      background: #fff
      display: flex
      justify-content: center
      align-items: center
      z-index: 2

    .buttons
      justify-content: space-between
      align-items: flex-end
      margin-top: 20px

  .actions
    & > div
      display: flex
      justify-content: center
      align-items: center
      margin-bottom: 20px

      .big-btn
        height: 100%
        cursor: pointer
        margin-top: 40px
