@import 'utils/_variables'

@import 'react-big-calendar/lib/sass/styles'
// @import 'react-big-calendar/lib/addons/dragAndDrop/styles'

*
  box-sizing: border-box

html, body
  padding: 0
  font-size: 17px

.container
  display: flex
  width: 100vw


.content
  padding: 40px 40px
  width: calc(100vw - $sidebar-width)
  margin-left: auto
  margin-top: $topNavHeight
  position: relative
  height: calc(100vh - $topNavHeight)
  overflow-x: hidden
  overflow-y: auto

.App.role-worker .content
  width: 100vw!important

h1, h2, h3
  color: $primary-green

h1
  margin-top: 0

h2
  margin-bottom: 30px

a
  text-decoration: none
  color: $black

.overlay-link
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

.underline
  text-decoration: underline

.pointer
  cursor: pointer

.help
  cursor: help

.strong
  font-weight: bold

.error, .red
  color: $red

.success, .green
  color: $green

.primary
  color: $dark-green

.warning
  color: $orange

.gray
  color: $gray

.letter-icon
  height: calc(1rem + 6px)
  width: calc(1rem + 6px)
  font-size: .7rem
  font-weight: bold
  border-radius: 3px
  line-height: 1
  background: $gray
  color: $black
  display: flex
  align-items: center
  justify-content: center
  display: inline-flex
  text-decoration: none

.swatch
  width: 1rem
  height: 1rem
  border-radius: 50%
  display: inline-block

  &.yellow
    background: $yellow

  &.green
    background: $green

  &.orange
    background: $orange

  &.red
    background: $red

  &.black
    background: $black

  &.gray
    background: $gray

.action
  cursor: pointer

  &.disabled
    cursor: not-allowed
    opacity: .5
    color: $gray

.Login
  background: $gray-light
  min-width: calc(100vw - 40px)
  min-height: 100vh
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: 20px

  .logo
    width: 300px
    max-width: calc(100vw - 60px)
    margin: 0 auto 50px

.MuiTouchRipple-root
  display: none

.input
  display: block
  width: 100%

input.MuiOutlinedInput-input:not(.disabled-secondary):not(.disabled)
  &:disabled
    border-top: none
    border-left: none
    border-right: none
    border-radius: 0
    color: $black
    -webkit-text-fill-color: $black

    & ~ fieldset
      border-top: none
      border-left: none
      border-right: none
      border-radius: 0

.MuiSelect-select.Mui-disabled
  color: $black!important
  -webkit-text-fill-color: $black!important

  & ~ fieldset
    border-top: none
    border-left: none
    border-right: none
    border-radius: 0

  & ~ svg
    display: none

input.MuiOutlinedInput-input.disabled-secondary:not(.disabled)
  &:disabled
    color: $black
    -webkit-text-fill-color: $black

.input-with-unit
  & > div:first-of-type
    width: 65%

  & > div:last-of-type
    width: 35%

.plus-btn
  font-size: 14px
  height: 18px
  width: 18px
  line-height: 1
  border: 2px solid $black
  border-radius: 50%
  margin-right: 6px
  display: inline-flex
  align-items: center
  justify-content: center
  position: relative
  bottom: 1px

.dropdown-btn-container
  position: relative
  display: inline-block

  .dropdown
    position: absolute
    top: 99%
    right: -10px
    background: $white
    box-shadow: 0 10px 30px rgba(0,0,0,.24)
    z-index: 3
    display: none
    border-radius: 4px
    flex-direction: column

    .dropdown-btn
      line-height: 1
      padding: 15px 20px
      cursor: pointer
      text-decoration: none
      color: $black
      display: inline-block
      border-bottom: 1px solid $gray

      &:last-of-type
        border-bottom: none

  &:hover
    .dropdown
      display: flex

.modal-close-btn, .modal-close
  position: absolute
  right: 5px
  top: 5px
  cursor: pointer
  color: $black

.MuiModal-root > .modal-close
  display: none

.header-bar
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 15px

  &.mb-10
    margin-bottom: 10px

  h1, h2, h3, h4
    margin-bottom: 0!important
    margin-top: 0

  img
    width: 50px

.flex
  display: flex
  flex-wrap: wrap

  &.nowrap
    flex-wrap: nowrap

  &.align-center
    align-items: center

  &.space-between
    justify-content: space-between

  &.justify-end
    justify-content: flex-end

  &.flex-column
    flex-direction: column

  @for $i from 1 through 12
    &.of-#{$i}
      & > *
        width: calc(100% / $i)

        @media (max-width: 767px)
          width: 100%

  div.twenty
    width: 20%
  div.twentyfive
    width: 25%
  div.thirty
    width: 30%
  div.fourty
    width: 40%
  div.fifty
    width: 50%
  div.sixty
    width: 60%
  div.seventy
    width: 70%
  div.seventyfive
    width: 75%

  &.spacing-1
    position: relative
    left: -7px
    width: calc(100% + 14px)

    & > *
      padding-left: 7px
      padding-right: 7px

.text-center, .text-center *
  text-align: center!important

.text-right
  text-align: right

.MuiTableHead-root
  .MuiTableCell-root
    font-weight: bold

.MuiTableBody-root
  .MuiTableRow-root
    &.active
      background: $green-light

    &.double-border
      border-top: 2px solid $gray

    .MuiTableCell-root:nth-child(1)
      font-weight: bold

    &.footer-row
      position: sticky
      bottom: 0
      left: 0
      width: 100%
      background: $white

      td
        border-top: 1px solid $gray
        font-weight: bold

table
  td, th
    white-space: nowrap

.windowed
  height: 30vh
  overflow-y: scroll
  position: relative
  padding: 15px
  border: 1px solid $gray

  .table
    margin-top: 30px

table
  tbody
    tr
      &:hover
        background-color: $gray-light

.MuiBox-root
  border-radius: 5px

  h2
    margin-top: 0

.MuiFormHelperText-root
  line-height: 1.2!important
  margin-top: 8px!important

//buttons
.MuiButton-root.MuiButton-contained
  &.MuiButton-containedSecondary
    background-color: $gray-light
    color: $black

.big-btn
  display: flex
  background: $secondary-green
  color: $black
  text-decoration: none
  align-items: center
  justify-content: center
  width: 300px
  max-width: 100%
  padding: 30px 40px
  font-weight: bold
  box-shadow: 0 10px 10px rgba(0,0,0,.1)
  cursor: pointer
  border-radius: 5px
  border: 1px solid $gray

  &.yellow
    background: $yellow

  &.disabled
    background: $gray-light
    color: $gray

  &.with-icon > div
    &:first-of-type
      width: 30%

    &:last-of-type
      width: 70%

  &:not(.with-icon)
    svg, img
      margin-right: 20px

  svg
    font-size: 50px

  img
    width: 50px

//calendar
.rbc-calendar
  .rbc-toolbar
    justify-content: space-between

    .rbc-toolbar-label
      position: absolute
      left: 0
      right: 0

    .rbc-btn-group
      z-index: 2

      button
        cursor: pointer

      &:first-of-type
        button
          &:first-of-type
            margin-right: 10px
            border-radius: 4px

          &:nth-child(2)
            border-bottom-left-radius: 4px
            border-top-left-radius: 4px

      &:last-of-type
        button
          &:nth-child(2)
            border-bottom-right-radius: 4px
            border-top-right-radius: 4px

          &:nth-child(3),
          &:nth-child(4)
            display: none

  .rbc-month-row
    min-height: 120px

  .rbc-day-slot .rbc-events-container
    margin-right: 0

hr
  height: 1px
  margin: 30px auto
  background: rgba(96, 187, 69)
  border: none

.total-bar
  position: fixed
  bottom: 0
  right: 0
  width: calc(100vw - $sidebar-width)
  background: $white
  padding: 20px 50px
  border-top: 1px solid $gray
  display: flex
  align-items: center
  justify-content: space-between

  & > div:nth-child(2)
    display: flex

    & > div:first-of-type
      margin-right: 80px

.td-with-icon
  .icon
    opacity: 0
    transition: all .2s

    &.active
      opacity: .3

    &:first-of-type
      margin-left: 20px

  &:hover
    .icon
      opacity: .3

.dashboard-calendar
  width: 500px
  height: 500px
  