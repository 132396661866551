.ProductionSettings
  .input-column
    display: flex
    justify-content: space-between
    margin-bottom: 20px
    max-width: 600px

    &:last-of-type
      margin-bottom: 0

    & > div
      width: calc(50% - 10px)

    &.combined
      & > div
        width: 50%

      input
        text-align: center

      & > div:first-of-type
        .MuiOutlinedInput-notchedOutline
          border-right: none
          border-top-right-radius: 0
          border-bottom-right-radius: 0

      & > div:last-of-type
        .MuiOutlinedInput-notchedOutline
          border-left: none
          border-top-left-radius: 0
          border-bottom-left-radius: 0
